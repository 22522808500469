import React, {Fragment, useEffect, useState} from 'react';
import Logo from '../../assets/logo.png';
import './DMVRegistration.css';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {TextField} from '@mui/material';
import Cross from '../../assets/cross_icon.svg';
import Plaid from './../../assets/plaid.png';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
{
  /*This Code is for DMV Registration Page.
It consists of a page and 3 Nested Modals.
First Part is Main Content which contains the content for the hero part of this page.
Then if the user wants to register a DMV, 3 Nested Modals will open: Modal->ChildModal->ChildChildModal */
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildChildModal({userData}) {
  const [id, setId] = useState ('');
  const [open, setOpen] = React.useState (false);
  const [admins, setAdmins] = useState ({
    admins: [
      {
        name: '',
        email: '',
      },
      {
        name: '',
        email: '',
      },
    ],
  });
  const handleInputChange = (index, event) => {
    const {name, value} = event.target;
    const newAdmins = [...admins.admins];
    newAdmins[index][name] = value;
    setAdmins ({admins: newAdmins});
    console.log (admins);
  };

  const handleOpen = async e => {
    e.preventDefault ();

    try {
      const response = await axios.post (
        'http://54.235.239.236:4000/dmv',
        userData
      );

      console.log ('Response:', response.data);
      setId (response.data._id);
      console.log (id);
    } catch (error) {
      console.error ('Error:', error);
    }
    setOpen (true);
  };
  const handleAdminData = async e => {
    e.preventDefault ();
    try {
      const response = await axios.post (
        `http://54.235.239.236:4000/dmv/${id}/admins/add`,
        admins
      );
      navigate ('/delegateOnboarding');
    } catch (error) {
      console.error ('Error:', error);
    }
    console.log ('dmv id is', id);
  };
  const handleClose = () => {
    setOpen (false);
  };
  const navigate = useNavigate ();
  return (
    <React.Fragment>
      <Button onClick={handleOpen} variant="contained" id="but-3">
        Next
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-child-modal-title"
        aria-describedby="child-child-modal-description"
      >
        <Box sx={{...style}} id="bx-2">
          <img id="cross" src={Cross} alt="cross" onClick={handleClose} />
          <h2 id="modal-modal-title">Nominate Atleast 2 Admins</h2>
          <p id="modal-modal-description">
            <TextField
              id="txtfield"
              varitant="outlined"
              label="First Admin Name:"
              name="name"
              value={admins.admins[0].name}
              onChange={event => handleInputChange (0, event)}
            />
            <hr />
            <TextField
              id="txtfield"
              varitant="outlined"
              label="First Admin Email Id:"
              name="email"
              value={admins.admins[0].email}
              onChange={event => handleInputChange (0, event)}
            />
            <hr />
            <TextField
              id="txtfield"
              varitant="outlined"
              label="Second Admin Name:"
              name="name"
              value={admins.admins[1].name}
              onChange={event => handleInputChange (1, event)}
            />
            <hr />
            <TextField
              id="txtfield"
              varitant="outlined"
              label="Second Admin Email Id:"
              name="email"
              value={admins.admins[1].email}
              onChange={event => handleInputChange (1, event)}
            />
            <hr />
            <hr />
          </p>
          {/* <Button onClick={handleClose} /> */}
          {/* Invite Delegates then the vote takes place, there needs to be consensus among everyone.*/}
          <Button
            variant="contained"
            className="invitebutton"
            onClick={handleAdminData}
          >
            Invite Delegates
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

function DMVRegistration () {
  const [open, setOpen] = React.useState (false);
  const handleOpen = () => setOpen (true);
  const handleClose = () => setOpen (false);

  const navigate = useNavigate ();
  const [userData, setUserData] = useState ({
    name: '',
    email: '',
    state: '',
    departmenthead: '',
  });
  const handleChange = e => {
    setUserData ({...userData, [e.target.name]: e.target.value});
    console.log (userData);
  };

  return (
    <div className="dmv-wrapper">
      <div className="logo-img">
        <img src={Logo} id="logo-img" alt="logo" />
      </div>
      <div className="main-content">
        <p id="txt-1">Making motor vehicle sharing easy</p>
        <p id="txt-2">For You, For Us, For Everyone</p>
        <p id="txt-3">
          A private communications and data-sharing gateway for DMVs to process titles and transfer ownership for businesses licensed to buy, sell, and trade in motor vehicles.
          {' '}
        </p>
      </div>
      <Button onClick={handleOpen} id="btn-1" variant="contained">
        Create your DMV Identity Now
      </Button>
      <Modal
        id="modal-1"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id="bx-1">
          <img id="cross" src={Cross} alt="cross" onClick={handleClose} />

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Tell us about your organisation
          </Typography>

          <Typography id="modal-modal-description" sx={{mt: 2}}>
            <TextField
              id="txtfield"
              variant="outlined"
              label="State:"
              name="state"
              value={userData.state}
              onChange={handleChange}
            />
            <hr />
            <TextField
              id="txtfield"
              varitant="outlined"
              label="Department Name:"
              name="name"
              value={userData.name}
              onChange={handleChange}
            />
            <hr />
            <TextField
              id="txtfield"
              varitant="outlined"
              label="Department Head:"
              name="departmenthead"
              value={userData.departmenthead}
              onChange={handleChange}
            />
            <hr />
            <TextField
              id="txtfield"
              varitant="outlined"
              label="Government Email Id:"
              name="email"
              value={userData.email}
              onChange={handleChange}
            />
            <hr />
            <hr />
            {/* <Button variant="contained" id="but-3">Next</Button> */}
            {/* onClick={handleSubmit} */}
          </Typography>
          <ChildChildModal userData={userData} />
        </Box>
      </Modal>
      <div className="steps">
        <h3>Let's Walk You Through The process</h3>
        <Step count={1} title={'Invite Delegates'}>
          <p>
            In order to verify and issue verifiable credentials to various
            dealers, we need to have details of witnesses who will work on the
            same
          </p>
          <p>
            The witnesses will be responsible for everything related to
            verifiable credentials.
          </p>
        </Step>

        <Step count={2} title={'Connect Your Bank'}>
          <p>
            To verify the business details we will need to connect to your bank
            account through our partner:
          </p>
          <img src={Plaid} alt="plaid logo" />
          <p>
            The business details, such as name and address, need to match that
            or your bank account.
          </p>
        </Step>

        <Step count={3} title={'Add Users from DMV'}>
          <p>Add your employees so they can start the work</p>
          <p>
            The business details, such as name and address, need to match that
            or your bank account.
          </p>
        </Step>
      </div>
    </div>
  );
}

const Step = ({count, title, children, ...extraProps}) => {
  return (
    <Fragment>
      <div className="step">
        <h3>
          <span>Step {count}:</span>
          {title}
        </h3>
        {children}
      </div>
    </Fragment>
  );
};

export default DMVRegistration;
