import './App.css';
import Landing from './pages/Landing/Landing';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import DMVRegistration from './pages/DMVRegistration/DMVRegistration';
import DelegateOnboarding from './pages/DelegateOnboarding/DelegateOnboarding';
import Dashboard, {Delegates, Wallet} from './components/Dashboard/Dashboard';

function App () {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/Landing" element={<Landing />} />
          <Route path="/DMVRegistration" element={<DMVRegistration />} />
          <Route path="/DelegateOnboarding" element={<DelegateOnboarding />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index path="wallet" element={<Wallet />} />
            <Route path="delegates" element={<Delegates />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
