import {Fragment} from 'react';
import styles from './Dashboard.module.css';
import window from './../../assets/window.png';
import docs from './../../assets/doc.png';
import wallet from './../../assets/wallet.png';
import download from './../../assets/download.png';
import person from './../../assets/person.png';
import settings from './../../assets/settings.png';
import coins from './../../assets/coin.svg';
import rewards from './../../assets/rewards.png';
import trophy from './../../assets/trophy.png';
import {Outlet, useNavigate} from 'react-router-dom';

const Dashboard = () => {
  return (
    <Fragment>
      <div className={styles.dashboardWrapper}>
        <header>
          <h1>TITLEBLOX</h1>
        </header>
        <SideBar />
        <main>
          <Outlet />
        </main>
      </div>
    </Fragment>
  );
};

const SideBar = () => {
  const navigate = useNavigate ();
  return (
    <Fragment>
      <div className={styles.sideBarWrapper}>
        <img src={window} alt="" />
        <img src={docs} alt="" />
        <img
          src={wallet}
          alt=""
          onClick={() => navigate ('/dashboard/wallet')}
        />
        <img src={download} alt="" />
        <img
          src={person}
          alt=""
          onClick={() => navigate ('/dashboard/delegates')}
        />
        <img src={settings} alt="" />
      </div>
    </Fragment>
  );
};

export const Wallet = () => {
  return (
    <div className={styles.walletWrapper}>
      <h1>Wallet</h1>
      <main>
        <div className={styles.amounts}>
          <div className={styles.amount}>
            $500.00
            <span>Balance</span>
            <img src={coins} alt="" />
          </div>
          <div className={styles.btns}>
            <button>Withdraw</button>
            <button>Deposit</button>
          </div>
        </div>
        <div className={styles.rewards}>
          <h1>Rewards</h1>
          <img src={rewards} alt="" className={styles.rewardsImg} />
          <img src={trophy} alt="" className={styles.trophy} />
        </div>
      </main>
    </div>
  );
};

export const Delegates = () => {
  const admins = ['Aaron Wuchner', 'John Doe'];
  const staff = [
    'Deepanshi Sharma',
    'Amit Goyal',
    'Jogesh Gupta',
    'Uday Beswal',
  ];
  return (
    <Fragment>
      <div className={styles.delegatesWrapper}>
        <div className={styles.admins}>
          <h1>Approve Admins</h1>
          {admins.map ((user, index) => <User key={index} name={user} />)}
        </div>
        <div className={styles.staff}>
          <h1>Approve Staff</h1>
          {staff.map ((user, index) => <User key={index} name={user} />)}
        </div>
      </div>
    </Fragment>
  );
};

const User = ({name}) => {
  return (
    <Fragment>
      <div className={styles.user}>
        <p>{name}</p>
        <div className={styles.userButtons}>
          <button type="button">Approve</button>
          <button type="button">Reject</button>
          <button type="button">Hold</button>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
