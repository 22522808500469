import React from 'react';
import Info2 from "../../assets/Info-2.png";
import Styles from "./LandingInfo2.module.css";

const LandingInfo2 = () => {
  return (
      <div className={Styles.Info2}>
        <div className={Styles.leftco}>
                <img src={Info2}/>
            </div>
            <div className={Styles.rightco}>
              <h3 className={Styles.infohead}>Authenticity. Security. Integrity.</h3>
              <p className={Styles.infopara}>At Carmony Labs, we are a pioneering tech company dedicated to creating custom software solutions and harnessing data-driven insights to drive innovation across various industries. With expertise in cutting-edge technologies such as AI, blockchain, and IoT, we offer client-centric consultation and strategy to help businesses thrive in a rapidly evolving landscape. Our passionate team of professionals is committed to delivering excellence in every project, all while staying true to our vision of being a catalyst for positive change and a brighter, tech-enabled future.</p>
            </div>
            
        </div>
  )
}

export default LandingInfo2
